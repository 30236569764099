<script>
import appConfig from '@src/app.config'
import Layout from '@layouts/main'
import axios from '@/axios'
import Multiselect from 'vue-multiselect'
import vue2Dropzone from 'vue2-dropzone'
import 'vue2-dropzone/dist/vue2Dropzone.min.css'
import { validationMixin } from 'vuelidate'
import { required, minLength } from 'vuelidate/lib/validators'
import { BASE_IMAGE_URL } from '@src/config/configs'
import Loading from 'vue-loading-overlay'
import 'vue-loading-overlay/dist/vue-loading.css'
import { VueEditor, Quill } from 'vue2-editor'
import ImageResize from 'quill-image-resize-module'

Quill.register('modules/imageResize', ImageResize)

/**
 * Contents component
 */
export default {
  page: {
    title: 'Contentandcategory',
    meta: [{ name: 'description', content: appConfig.description }],
  },
  components: {
    Layout,
    Multiselect,
    vueDropzone: vue2Dropzone,
    VueEditor,
    Loading,
  },

  data() {
    return {
      options: [],
      search: '',
      bizBucket: [],

      editorSettings: {
        modules: {
          imageResize: {},
        },
      },

      isLoading: false,
      baseImageUrl: BASE_IMAGE_URL,
      form: {
        status: true,
        title: '',
        summary :  '',
        image: [],
        new_image: [],

      },

      dropzoneOptions: {
        url: 'https://httpbin.org/post',
        thumbnailWidth: 340,
        maxFilesize: 5,
        parallelUploads: 50,
        paramName: 'images',
        uploadMultiple: true,
        acceptedFiles: 'image/*',      
        addRemoveLinks: true,
        acceptedFiles: '.jpeg,.jpg,.png,.gif',
        dictDefaultMessage: "<i class='fa fa-cloud-upload'></i> UPLOAD ME",
      },
      ismanuallyadd: false,
      isEdit: false,
    }
  },
  validations: {
    form: {
      title: {
        required,
      },         
      summary: {
        required,
      },
 
    },
  },
  mounted() {
    const id = this.$route.params.id
    this.initialUpdateItem(id)
  },
  computed: {
    criteria() {
      // Compute the search criteria
      return this.search.trim().toLowerCase()
    },
    availableOptions() {
      const criteria = this.criteria
      // Filter out already selected options
      const options = this.options.filter(opt => !this.bizBucket.some(tag => tag.value === opt.value))
      if (criteria) {
        // Show only options that match criteria
        return options.filter(opt => opt.text.toLowerCase().indexOf(criteria) > -1);
      }
      // Show all options available
      return options
    },
    searchDesc() {
      if (this.criteria && this.availableOptions.length === 0) {
        return 'There are no tags matching your search criteria'
      }
      return ''
    }
  },
  watch: {
   /*  value(val) {
        console.log(val)
        this.parseValue = this.tags.filter(e => this.value.includes(e.text))
        console.log(this.parseValue)
    } */
  },
  methods: {
    doAjax() {
      this.isLoading = true
      // simulate AJAX
    },
    onCancel() {
      this.isLoading = false

      // console.log('User cancelled the loader.')
    },

    vfileAdded(file) {

      this.form.new_image = file

      this.ismanuallyadd = false
    },
    vfilemanuallyAdded(file) {
      this.ismanuallyadd = true
    },

    validateState(name) {
      const { $dirty, $error } = this.$v.form[name]
      return $dirty ? !$error : null
    },

    getValidationState({ dirty, validated, valid = null }) {
      return dirty || validated ? valid : null
    },

    async removeImage(picId){
      const request = axios.get(`/api/content/category/removeImage/${this.$route.params.id}/${picId}`)
      
      await request
        .then(async (result) => {
          console.log("r =>" + result)
          this.onCancel()
          this.$router.go(0)
        })
        .catch((err) => {
          this.onCancel()
          this.$toast.error(err.response.data.error.message)
        })
    },

  async formSubmit() {
      this.doAjax()
  
      let businessId = [];
      let bizBucket = this.bizBucket.map((jsonString) => JSON.parse(jsonString));

      await bizBucket.forEach((ele) => {
        businessId.push(ele.value);
      });

      let body = {
        title : this.form.title,   
        summary : this.form.summary,
        business : businessId
      }
      
      const data = new FormData()
      if (!this.ismanuallyadd) {
        for (const i of Object.keys(this.form.new_image)) {

          data.append('image', this.form.new_image[i])
        }
      }

      data.append('data', JSON.stringify(body))

      const paramsId = this.$route.params.id
      const request = axios.post(`/api/content/category/edit/${paramsId}`, data)

      await request
        .then(async (result) => {     
          this.onCancel()
          this.$toast.success('Saved successfully')
          this.$router.push('/contentandcategory')
        })
        .catch((err) => {
          this.onCancel()
          this.$toast.error(err.response.data.error.message)
        })
    },


    async initialUpdateItem(id) {
      if (!id) return

      await axios.get(`api/content/category/edit/${id}`).then((res) => {
        console.log(res.data)
        this.form = { ...this.form, ...res.data.category }

        this.options = res.data.business;
        this.bizBucket = res.data.category.businessId

      })
      
    },
    filterOptions(value, option) {
      return option.text.toLowerCase().includes(value.toLowerCase());
    },
    onOptionClick(option) {
      this.bizBucket.push(option)
      this.search = ''
    },
    removeTag(tag) {
      const index = this.bizBucket.findIndex(t => t.value === tag.value)
      if (index !== -1) {
        this.bizBucket.splice(index, 1)
      }
    },
  },
}
</script>
<template>
  <Layout>
    <div class="row page-title align-items-center">
      <div class="col-md-3 col-xl-6">
        <h4 class="mb-1 mt-0">Content And Category</h4>
      </div>
      <div class="row mt-2" style="height:100%; width:100%;">
        <div class="col-12">
          <div class="card">
            <div class="card-body">
              <b-form class="form-horizontal" @submit.prevent="formSubmit">
                <div class="row center">
                  <div class="col-7">
                    <b-form-group label-cols-lg="0">
                      <label>Title</label>
                      <b-form-input
                        id="title"
                        v-model="$v.form.title.$model"
                        placeholder="Enter title..."
                        name="title"
                        type="text"
                        :state="validateState('title')"
                        aria-describedby="input-1-live-feedback"
                      ></b-form-input>
                      <b-form-invalid-feedback id="input-1-live-feedback"
                        >This is a required field</b-form-invalid-feedback
                      >
                    </b-form-group>

                    <b-form-group label-cols-lg="0">
                      <label>Summary</label>

                      <vue-editor
                        :use-custom-image-handler="true"
                        @image-added="handleImageAdded"
                        v-model="form.summary"
                        :editorOptions="editorSettings"
                        height="300"
                      ></vue-editor>
                    </b-form-group>


                    <b-form-group label-cols-lg="0">
                      <label>Image</label>
                    <div>
                      <b-card-group deck>
                      <div v-for="item in this.form.image" :key="item._id">
                      <b-card                     
                       :img-src="`${baseImageUrl}/${item.url}`"
                       img-alt="Image"
                       img-top
                       tag="article"
                       style="max-width: 10rem;"
                       class="mb-2"
                      >
                      <b-button @click="removeImage(item._id)" variant="danger">Delete</b-button>
                      </b-card>
                      </div>

                    </b-card-group>

                      </div>
                    </b-form-group>


                    <b-form-group  label-cols-lg="0">
                      <label>
                        Upload Image
                        <small class="color-text-small"
                          >Width 500 x Height 500. Just only one.</small
                        >
                      </label>
  
                      <vue-dropzone 
                        ref="myVueDropzone" 
                        id="form-image" 
                        accepted-files="image/*"
                        :options="dropzoneOptions"
                        :useCustomSlot="true" 
                        @vdropzone-success-multiple="vfileAdded" 
                      ></vue-dropzone>
                    </b-form-group>
                    <div class="mt-5 mb-5">
                      <b-form-group label="Choose Business" label-for="tags-with-dropdown">
                        <b-form-tags id="tags-with-dropdown" v-model="bizBucket" no-outer-focus class="mb-2">
                          <template v-slot="{ tags, disabled, addTag, removeTag }">
                            <ul v-if="tags.length > 0" class="list-inline d-inline-block mb-2">
                              <li v-for="tag in tags" :key="tag.bizBucket" class="list-inline-item">
                                <b-form-tag @remove="removeTag(tag)" :title="tag.text" :disabled="disabled"
                                  variant="info">{{ JSON.parse(tag).text }}</b-form-tag>
                              </li>
                            </ul>


                            <b-dropdown size="sm" variant="outline-secondary" block menu-class="w-100">
                              <template #button-content>
                                <b-icon icon="tag-fill"></b-icon> Choose tags
                              </template>
                              <b-dropdown-form @submit.stop.prevent="() => { }">
                                <b-form-group label="Search tags" label-for="tag-search-input" label-cols-md="auto"
                                  class="mb-0" label-size="sm" :description="searchDesc" :disabled="disabled">
                                  <b-form-input v-model="search" id="tag-search-input" type="search" size="sm"
                                    autocomplete="off"></b-form-input>
                                </b-form-group>
                              </b-dropdown-form>
                              <b-dropdown-divider></b-dropdown-divider>
                              <div class="overflow-auto" style="max-height: 200px;">
                                <b-dropdown-item-button v-for="option in availableOptions" :key="option.value"
                                  @click="onOptionClick(option)">
                                  {{ option.text }}
                                </b-dropdown-item-button>
                                <b-dropdown-text v-if="availableOptions.length === 0">
                                  There are no tags available to select
                                </b-dropdown-text>
                              </div>

                            </b-dropdown>
                          </template>
                        </b-form-tags>
                      </b-form-group>
                    </div>


                    <loading
                      :active.sync="isLoading"
                      :can-cancel="false"
                      :on-cancel="onCancel"
                      :is-full-page="true"
                    ></loading>

   
                    <b-form-group>
                      <router-link to="/contentandcategory">
                        <button
                          type="button"
                          class="btn btn-danger float-right ml-2"
                          >Cancel</button
                        >
                      </router-link>
                      <button type="submit" class="btn btn-primary float-right"
                        >Save</button
                      >
                    </b-form-group>
                  </div>
                </div>
              </b-form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>

<style lang="scss" scope>
.unactiveClass {
  border: 1px solid #5269f785;
  background: #5269f785;
}
.dropdown-scrollable {
  max-height: 200px; /* Set the desired height for the dropdown */
  overflow-y: auto;
}
</style>
